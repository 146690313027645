import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/Docs.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Video = makeShortcode("Video");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Experimental Projects`}</h1>
    <h2>{`Studio Collective Jam`}</h2>
    <p>{`The Mars Project is a dance band concept featuring improvised instrumentals. You can watch a video of the most recent show here:`}</p>
    <h2>{`The Issues podcast`}</h2>
    <p>{`Events focused on conversation and music showcase.`}</p>
    <h4>{`Live in The Studio`}</h4>
    <Video videoId="Ys_jW27iVus" alt="Live in the Studio" mdxType="Video" />
    <h2>{`Thursday Therapy at F8 Folsom`}</h2>
    <p>{`Thursday Therapy is a monthly dance party with house and techno music with an emphasis on community and diversity of artists. We began running the event in the pandemic as a live-stream and have continued to adapt to changing realities of clubbing in today's world. For many of us, dancing together on Thursday nights was a big savior during a dark year.`}</p>
    <p>{`Thursday Therapy resident DJs are Tōsh, Cptn Jay, Kudeki, and Cara Mazzi.`}</p>
    <h2>{`Live set and music videos`}</h2>
    <h4>{`Live set in Nature`}</h4>
    <Video videoId="BBOHRUgyZB0" alt="Live Set Video" mdxType="Video" />
    <h4>{`Music video shot in NYC`}</h4>
    <Video videoId="tlsDIOyZsig" alt="City Walks" mdxType="Video" />
    {
      /* ## Jukebox music sharing app
      An old project that is near completion but has been on the backburner. */
    }

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      